//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      activeName: 'en',
      imagesEN:[
        require('../../assets/esimplus/en_ios_0@2x.png'),
        require('../../assets/esimplus/en_ios_1@2x.png'),
        require('../../assets/esimplus/en_ios_2@2x.png'),
        require('../../assets/esimplus/en_ios_3@2x.png'),
        require('../../assets/esimplus/en_ios_4@2x.png'),
        require('../../assets/esimplus/en_ios_5@2x.png'),
        require('../../assets/esimplus/en_ios_6@2x.png'),
      ],
      imagesCN:[
        require('../../assets/esimplus/cn_ios_0@2x.png'),
        require('../../assets/esimplus/cn_ios_1@2x.png'),
        require('../../assets/esimplus/cn_ios_2@2x.png'),
        require('../../assets/esimplus/cn_ios_3@2x.png'),
        require('../../assets/esimplus/cn_ios_4@2x.png'),
        require('../../assets/esimplus/cn_ios_5@2x.png'),
        require('../../assets/esimplus/cn_ios_6@2x.png'),
      ],
      imagesHK:[
        require('../../assets/esimplus/hk_ios_0@2x.png'),
        require('../../assets/esimplus/hk_ios_1@2x.png'),
        require('../../assets/esimplus/hk_ios_2@2x.png'),
        require('../../assets/esimplus/hk_ios_3@2x.png'),
        require('../../assets/esimplus/hk_ios_4@2x.png'),
        require('../../assets/esimplus/hk_ios_5@2x.png'),
        require('../../assets/esimplus/hk_ios_6@2x.png'),
      ],
      imagesTW:[
        require('../../assets/esimplus/tw_ios_0@2x.png'),
        require('../../assets/esimplus/tw_ios_1@2x.png'),
        require('../../assets/esimplus/tw_ios_2@2x.png'),
        require('../../assets/esimplus/tw_ios_3@2x.png'),
        require('../../assets/esimplus/tw_ios_4@2x.png'),
        require('../../assets/esimplus/tw_ios_5@2x.png'),
        require('../../assets/esimplus/tw_ios_6@2x.png'),
      ],
    }
  },
}
