//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDataplanList } from '@/api/enquiry'
export default {
  data() {
    return {
      value:null,
      planList:[]
    }
  },
  methods:{
    onSearch(value){
      if(!value) {
        this.planList = []
        return
      }
      if (value&&value.length < 14) {
        this.$message.warning('卡號長度不符合要求')
        return
      }
      getDataplanList({ sim: value }).then(res => {
        this.planList = []
        if (res.code==1) {
          this.planList = res.result
        }else{
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
