import request from '@/utils/request'

const api = {
 url: '/shopify/checkDeviceId'
}

export function checkDeviceId (parameter) {
  return request({
    url: api.url,
    method: 'get',
    params: parameter
  })
}