//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gql from 'graphql-tag'
import moment from 'moment'
const customerOrdersGql = gql`query customerOrders($customerAccessToken:String!,$first: Int,$after: String,$last:Int,$before:String){
customerOrders:customer(customerAccessToken:$customerAccessToken){
    lastIncompleteCheckout{
      createdAt
      lineItemsSubtotalPrice{
        amount
        currencyCode
      }
      lineItems(first:1){
        edges{
          node{
            title
            variant{
              title
              image{
                url
              }
            }
            customAttributes{
              key
              value
            }
          }
        }
      }
    }
    orders(reverse:true,first:$first,after:$after,last:$last,before:$before){
      pageInfo{
        hasNextPage
        hasPreviousPage
      }
      edges{
        cursor
        node{
          name
          processedAt
          totalPriceV2{
            amount
            currencyCode
          }
          fulfillmentStatus
          successfulFulfillments(first:1){
            trackingCompany
            trackingInfo(first:1){
              number
              url
            }
          }
          lineItems(first:1){
            edges{
              node{
                title
                variant{
                  title
                  image{
                    url
                  }
                }
                originalTotalPrice{
                  amount
                  currencyCode
                }
                customAttributes{
                  key
                  value
                }
              }
            }
          }
        }
      }
    }
  }
}` 
export default {
  data() {
    return {
      status:1,
      spinning:false,
      customerOrders:[],
      Cancelleds:{},
      Fulfilleds:[],
      UnFulfilleds:[],
      pageInfo:{
        startCursor:null,
        endCursor:null,
        hasNextPage:true,
        hasPreviousPage:false,
      }
    }
  },
  filters: {
    timeFilter (time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  created(){
    this.getOrders({customerAccessToken:this.$cookie.get('TSimAccessToken'),first:5})
  },
  methods: {
    getOrders({...params}){
      this.spinning = true
      this.$apollo.addSmartQuery('customerOrders', {
        query: customerOrdersGql, variables: params
        , result({ data, loading, networkStatus}) {
            this.spinning = false
            this.Cancelleds = data.customerOrders.lastIncompleteCheckout
            this.customerOrders = data.customerOrders.orders.edges
            this.pageInfo.hasNextPage = data.customerOrders.orders.pageInfo.hasNextPage
            this.pageInfo.hasPreviousPage = data.customerOrders.orders.pageInfo.hasPreviousPage
            if(data.customerOrders.orders.edges.length>0){
              this.pageInfo.startCursor = data.customerOrders.orders.edges[0].cursor
              this.pageInfo.endCursor = data.customerOrders.orders.edges[data.customerOrders.orders.edges.length-1].cursor
            }
        },// 错误处理
        error(error) {
            this.spinning = false
            this.$message.error(error.message)
        }
      });
    },
    previous(){
      if(this.pageInfo.hasPreviousPage) this.getOrders({customerAccessToken:this.$cookie.get('TSimAccessToken'),last: 5,before:this.pageInfo.startCursor})
    },
    next(){
      if(this.pageInfo.hasNextPage) this.getOrders({customerAccessToken:this.$cookie.get('TSimAccessToken'),first: 5,after:this.pageInfo.endCursor})
    },
    tabChange(status){
      if (status===3) {
        this.spinning = true
        this.$apollo.addSmartQuery('Fulfilleds', {
          query: customerOrdersGql, variables: {
            customerAccessToken: this.$cookie.get('TSimAccessToken'),
            first:250
          }, result({ data, loading, networkStatus}) {
              this.spinning = false
              this.Fulfilleds = data.customerOrders.orders.edges.filter(item=> item.node.fulfillmentStatus === "FULFILLED")
          },// 错误处理
          error(error) {
              this.spinning = false
              this.$message.error(error.message)
          }
        });
      }
      if(status===4){
        this.spinning = true
        this.$apollo.addSmartQuery('UnFulfilleds', {
          query: customerOrdersGql, variables: {
            customerAccessToken: this.$cookie.get('TSimAccessToken'),
            first:250
          }, result({ data, loading, networkStatus}) {
              this.spinning = false
              this.UnFulfilleds = data.customerOrders.orders.edges.filter(item=>item.node.fulfillmentStatus === "OPEN" || item.node.fulfillmentStatus === "UNFULFILLED")
          },// 错误处理
          error(error) {
              this.spinning = false
              this.$message.error(error.message)
          }
        });
      }
      this.status = status
    },
  },
}
