//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      activeKey: [],
    };
  },
  computed: {
    columns() {
      const baseColumns = [
        {
          title: "Day(s)",
          dataIndex: "day",
          key: "day",
        },
        {
          title: this.$t('newplan.type'),
          dataIndex: "type",
          key: "type",
        },
        {
          title: this.$t('newplan.options'),
          dataIndex: "options",
          key: "0ptions",
        },
        {
          title: this.$t('newplan.jpprice'),
          dataIndex: "price",
          key: "price",
        },
      ];
      return baseColumns;
    },
    data() {
      const baseData = [
        {
          title: this.$t("country.asia"),
          array: [
            {
              key: 0,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 800,
            },
            {
              key: 1,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1200,
            },
            {
              key: 2,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1050,
            },
            {
              key: 4,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1500,
            },
            {
              key: 5,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1280,
            },
            {
              key: 6,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2000,
            },
            {
              key: 7,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2500,
            },
            {
              key: 8,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3800,
            },
            {
              key: 9,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1500,
            },
            {
              key: 10,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2200,
            },
            {
              key: 11,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4300,
            },
            {
              key: 12,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 4900,
            },
            {
              key: 13,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 6200,
            },
            {
              key: 14,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 9000,
            },
          ],
        },
        {
          title: this.$t("country.australia"),
          array: [
            {
              key: 15,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 990,
            },
            {
              key: 16,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1710,
            },
            {
              key: 17,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1750,
            },
            {
              key: 18,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3130,
            },
            {
              key: 19,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3160,
            },
            {
              key: 20,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 5800,
            },
            {
              key: 21,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 960,
            },
            {
              key: 22,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1380,
            },
            {
              key: 23,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2370,
            },
          ],
        },
        {
          title: this.$t("country.Bali"),
          array: [
            {
              key: 24,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 460,
            },
            {
              key: 25,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 760,
            },
            {
              key: 26,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 690,
            },
            {
              key: 27,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1160,
            },
            {
              key: 28,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 760,
            },
            {
              key: 29,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 890,
            },
            {
              key: 30,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1580,
            },
            {
              key: 31,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1090,
            },
            {
              key: 32,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1190,
            },
            {
              key: 33,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2140,
            },
            {
              key: 34,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2240,
            },
            {
              key: 35,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1610,
            },
            {
              key: 36,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2930,
            },
            {
              key: 37,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 3380,
            },
          ],
        },
        {
          title: this.$t("country.China"),
          array: [
            {
              key: 38,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 790,
            },
            {
              key: 39,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1320,
            },
            {
              key: 40,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1220,
            },
            {
              key: 41,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2140,
            },
            {
              key: 42,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1650,
            },
            {
              key: 43,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2930,
            },
            {
              key: 44,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2240,
            },
            {
              key: 45,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4020,
            },
            {
              key: 46,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3100,
            },
            {
              key: 47,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 5510,
            },
            {
              key: 48,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1370,
            },
            {
              key: 49,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2340,
            },
            {
              key: 50,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 4450,
            },
            {
              key: 51,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 6430,
            },
          ],
        },
        {
          title: this.$t("country.Denmark"),
          array: [
            {
              key: 52,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 960,
            },
            {
              key: 53,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1650,
            },
            {
              key: 54,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1680,
            },
            {
              key: 55,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3030,
            },
            {
              key: 56,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3100,
            },
            {
              key: 57,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 5670,
            },
            {
              key: 58,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 920,
            },
            {
              key: 59,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1350,
            },
            {
              key: 60,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2340,
            },
          ],
        },
        {
          title: this.$t("country.Dubai"),
          array: [
            {
              key: 61,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 860,
            },
            {
              key: 62,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1520,
            },
            {
              key: 63,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1350,
            },
            {
              key: 64,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2410,
            },
            {
              key: 65,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1470,
            },
            {
              key: 66,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1810,
            },
            {
              key: 67,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3330,
            },
            {
              key: 68,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2210,
            },
            {
              key: 69,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3400,
            },
            {
              key: 70,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 6300,
            },
            {
              key: 71,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4660,
            },
            {
              key: 72,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 6400,
            },
            {
              key: 73,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 11900,
            },
            {
              key: 74,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 7140,
            },
          ],
        },
        {
          title: this.$t("country.europe"),
          array: [
            {
              key: 75,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1340,
            },
            {
              key: 76,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2360,
            },
            {
              key: 77,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4160,
            },
            {
              key: 78,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2400,
            },
            {
              key: 79,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 4360,
            },
            {
              key: 80,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 7730,
            },
            {
              key: 81,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 4440,
            },
            {
              key: 82,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 8130,
            },
            {
              key: 83,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 14520,
            },
            {
              key: 84,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1300,
            },
            {
              key: 85,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1920,
            },
            {
              key: 86,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 3340,
            },
          ],
        },
        {
          title: this.$t("country.guam"),
          array: [
            {
              key: 87,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2720,
            },
            {
              key: 88,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 4730,
            },
            {
              key: 89,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 5040,
            },
            {
              key: 90,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 8820,
            },
          ],
        },
        {
          title: this.$t("country.hawaii"),
          array: [
            {
              key: 91,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 560,
            },
            {
              key: 92,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 960,
            },
            {
              key: 93,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 860,
            },
            {
              key: 94,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1520,
            },
            {
              key: 95,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 740,
            },
            {
              key: 96,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1160,
            },
            {
              key: 97,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2040,
            },
            {
              key: 98,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1050,
            },
            {
              key: 99,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1550,
            },
            {
              key: 100,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2800,
            },
            {
              key: 101,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2100,
            },
            {
              key: 102,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2110,
            },
            {
              key: 103,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3820,
            },
            {
              key: 104,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 3260,
            },
          ],
        },
        {
          title: this.$t("country.hongkong"),
          array: [
            {
              key: 105,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 700,
            },
            {
              key: 106,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2840,
            },
            {
              key: 107,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1000,
            },
            {
              key: 108,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 1300,
            },
            {
              key: 109,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3890,
            },
            {
              key: 110,
              day: 10,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1600,
            },
            {
              key: 111,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1900,
            },
            {
              key: 112,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 1600,
            },
            {
              key: 113,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 7250,
            },
            {
              key: 114,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1200,
            },
            {
              key: 115,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1650,
            },
            {
              key: 116,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2100,
            },
            {
              key: 117,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 3300,
            },
            {
              key: 118,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 4500,
            },
          ],
        },
        {
          title: this.$t("country.iceland"),
          array: [
            {
              key: 119,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 960,
            },
            {
              key: 120,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1680,
            },
            {
              key: 121,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3100,
            },
            {
              key: 122,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 920,
            },
            {
              key: 123,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1350,
            },
            {
              key: 124,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2340,
            },
          ],
        },
        {
          title: this.$t("country.india"),
          array: [
            {
              key: 125,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 300MB",
              price: 1290,
            },
            {
              key: 126,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2140,
            },
            {
              key: 127,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 300MB",
              price: 2430,
            },
            {
              key: 128,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 4060,
            },
            {
              key: 129,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 300MB",
              price: 4590,
            },
            {
              key: 130,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 7650,
            },
            {
              key: 131,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 2110,
            },
            {
              key: 132,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 3230,
            },
            {
              key: 133,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 5670,
            },
          ],
        },
        {
          title: this.$t("country.indonesia"),
          array: [
            {
              key: 134,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 760,
            },
            {
              key: 135,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1250,
            },
            {
              key: 136,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1160,
            },
            {
              key: 137,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2010,
            },
            {
              key: 138,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1580,
            },
            {
              key: 139,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2770,
            },
            {
              key: 140,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2140,
            },
            {
              key: 141,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3790,
            },
            {
              key: 142,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2930,
            },
            {
              key: 143,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 5210,
            },
            {
              key: 144,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1300,
            },
            {
              key: 145,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2240,
            },
            {
              key: 146,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 4220,
            },
            {
              key: 147,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 6070,
            },
          ],
        },
        {
          title: this.$t("country.Israel"),
          array: [
            {
              key: 148,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 560,
            },
            {
              key: 149,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 960,
            },
            {
              key: 150,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 860,
            },
            {
              key: 151,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1450,
            },
            {
              key: 152,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 920,
            },
            {
              key: 153,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1120,
            },
            {
              key: 154,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1980,
            },
            {
              key: 155,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1450,
            },
            {
              key: 156,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2010,
            },
            {
              key: 157,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3660,
            },
            {
              key: 158,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2700,
            },
            {
              key: 159,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 5280,
            },
          ],
        },
        {
          title: this.$t("country.japan"),
          array: [
            {
              key: 160,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1100,
            },
            {
              key: 161,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1300,
            },
            {
              key: 162,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1200,
            },
            {
              key: 163,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1500,
            },
            {
              key: 164,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1400,
            },
            {
              key: 165,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1700,
            },
            {
              key: 166,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1800,
            },
            {
              key: 167,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2200,
            },
            {
              key: 168,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1600,
            },
            {
              key: 169,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2300,
            },
            {
              key: 170,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 3300,
            },
            {
              key: 171,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 5000,
            },
            {
              key: 172,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 6500,
            },
            {
              key: 173,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 8200,
            },
          ],
        },
        {
          title: this.$t("country.korea"),
          array: [
            {
              key: 174,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 690,
            },
            {
              key: 175,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1160,
            },
            {
              key: 176,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1050,
            },
            {
              key: 177,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1850,
            },
            {
              key: 178,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1450,
            },
            {
              key: 179,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2540,
            },
            {
              key: 180,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1940,
            },
            {
              key: 181,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3460,
            },
            {
              key: 182,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2670,
            },
            {
              key: 183,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4750,
            },
            {
              key: 184,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1190,
            },
            {
              key: 185,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2040,
            },
            {
              key: 186,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 3860,
            },
            {
              key: 187,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 5540,
            },
          ],
        },
        {
          title: this.$t("country.macau"),
          array: [
            {
              key: 188,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1160,
            },
            {
              key: 189,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2010,
            },
            {
              key: 190,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1850,
            },
            {
              key: 191,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3230,
            },
            {
              key: 192,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2510,
            },
            {
              key: 193,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4450,
            },
            {
              key: 194,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3430,
            },
            {
              key: 195,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 6100,
            },
            {
              key: 196,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 4680,
            },
            {
              key: 197,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 8370,
            },
            {
              key: 198,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2020,
            },
            {
              key: 199,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4100,
            },
            {
              key: 200,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 5920,
            },
            {
              key: 201,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 8590,
            },
          ],
        },
        {
          title: this.$t("country.mainland"),
          array: [
            {
              key: 202,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2300,
            },
            {
              key: 203,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 2500,
            },
            {
              key: 204,
              day: 10,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2700,
            },
            {
              key: 205,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3800,
            },
            {
              key: 206,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 4300,
            },
            {
              key: 207,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 2300,
            },
            {
              key: 208,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 3500,
            },
            {
              key: 209,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4400,
            },
          ],
        },
        {
          title: this.$t("country.malaysia"),
          array: [
            {
              key: 210,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1100,
            },
            {
              key: 211,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1910,
            },
            {
              key: 212,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1750,
            },
            {
              key: 213,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3070,
            },
            {
              key: 214,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2380,
            },
            {
              key: 215,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4230,
            },
            {
              key: 216,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3260,
            },
            {
              key: 217,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 5800,
            },
            {
              key: 218,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 4450,
            },
            {
              key: 219,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 7960,
            },
            {
              key: 220,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1940,
            },
            {
              key: 221,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 3380,
            },
            {
              key: 222,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 6420,
            },
            {
              key: 223,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 9300,
            },
          ],
        },
        {
          title: this.$t("country.newzealand"),
          array: [
            {
              key: 224,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1580,
            },
            {
              key: 225,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2900,
            },
            {
              key: 226,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2970,
            },
            {
              key: 227,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 5470,
            },
            {
              key: 228,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 5570,
            },
            {
              key: 229,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 10320,
            },
            {
              key: 230,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1550,
            },
            {
              key: 231,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2370,
            },
            {
              key: 232,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4150,
            },
          ],
        },
        {
          title: this.$t("country.philippines"),
          array: [
            {
              key: 233,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1810,
            },
            {
              key: 234,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3330,
            },
            {
              key: 235,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3400,
            },
            {
              key: 236,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 6300,
            },
            {
              key: 237,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 6400,
            },
            {
              key: 238,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 11900,
            },
            {
              key: 239,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1780,
            },
            {
              key: 240,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2700,
            },
            {
              key: 241,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4750,
            },
          ],
        },
        {
          title: this.$t("country.saudi"),
          array: [
            {
              key: 242,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2140,
            },
            {
              key: 243,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3960,
            },
            {
              key: 244,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 6430,
            },
            {
              key: 245,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 4060,
            },
            {
              key: 246,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 7550,
            },
            {
              key: 247,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 12170,
            },
            {
              key: 248,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 2100,
            },
            {
              key: 249,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 3230,
            },
            {
              key: 250,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 5670,
            },
            {
              key: 251,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 10940,
            },
            {
              key: 252,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 15860,
            },
          ],
        },
        {
          title: this.$t("country.singapore"),
          array: [
            {
              key: 253,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 690,
            },
            {
              key: 254,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1120,
            },
            {
              key: 255,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1050,
            },
            {
              key: 256,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1810,
            },
            {
              key: 257,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1420,
            },
            {
              key: 258,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2470,
            },
            {
              key: 259,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1910,
            },
            {
              key: 260,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3360,
            },
            {
              key: 261,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2600,
            },
            {
              key: 262,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4620,
            },
            {
              key: 263,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1160,
            },
            {
              key: 264,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 1980,
            },
            {
              key: 265,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 3730,
            },
            {
              key: 266,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 5370,
            },
          ],
        },
        {
          title: this.$t("country.switzerland"),
          array: [
            {
              key: 267,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1120,
            },
            {
              key: 268,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2010,
            },
            {
              key: 269,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3730,
            },
            {
              key: 270,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1090,
            },
            {
              key: 271,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1610,
            },
            {
              key: 272,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2800,
            },
          ],
        },
        {
          title: this.$t("country.taiwan"),
          array: [
            {
              key: 273,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 660,
            },
            {
              key: 274,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1120,
            },
            {
              key: 275,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1940,
            },
            {
              key: 276,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 990,
            },
            {
              key: 277,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1780,
            },
            {
              key: 278,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3200,
            },
            {
              key: 279,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1120,
            },
            {
              key: 280,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1350,
            },
            {
              key: 281,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2470,
            },
            {
              key: 282,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4420,
            },
            {
              key: 283,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1650,
            },
            {
              key: 284,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2540,
            },
            {
              key: 285,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 4650,
            },
            {
              key: 286,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 8310,
            },
            {
              key: 287,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 3430,
            },
            {
              key: 288,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 4710,
            },
            {
              key: 289,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 8740,
            },
            {
              key: 290,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 15690,
            },
            {
              key: 291,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 6730,
            },
            {
              key: 292,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 9730,
            },
          ],
        },
        {
          title: this.$t("country.thailand"),
          array: [
            {
              key: 293,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 690,
            },
            {
              key: 294,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1120,
            },
            {
              key: 295,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1050,
            },
            {
              key: 296,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1810,
            },
            {
              key: 297,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1420,
            },
            {
              key: 298,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2470,
            },
            {
              key: 299,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1910,
            },
            {
              key: 300,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3360,
            },
            {
              key: 301,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2600,
            },
            {
              key: 302,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4620,
            },
            {
              key: 303,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1160,
            },
            {
              key: 304,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 1980,
            },
            {
              key: 305,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 3730,
            },
            {
              key: 306,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 5370,
            },
          ],
        },
        {
          title: this.$t("country.turkey"),
          array: [
            {
              key: 307,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 960,
            },
            {
              key: 308,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1680,
            },
            {
              key: 309,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3100,
            },
            {
              key: 310,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 920,
            },
            {
              key: 311,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1350,
            },
            {
              key: 312,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2340,
            },
          ],
        },
        {
          title: this.$t("country.united"),
          array: [
            {
              key: 313,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1810,
            },
            {
              key: 314,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3330,
            },
            {
              key: 315,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3400,
            },
            {
              key: 316,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 6300,
            },
            {
              key: 317,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 1780,
            },
            {
              key: 318,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 2700,
            },
            {
              key: 319,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 4750,
            },
          ],
        },
        {
          title: this.$t("country.usa"),
          array: [
            {
              key: 320,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 560,
            },
            {
              key: 321,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 960,
            },
            {
              key: 322,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 860,
            },
            {
              key: 323,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1520,
            },
            {
              key: 324,
              day: 5,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 760,
            },
            {
              key: 325,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1160,
            },
            {
              key: 326,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2040,
            },
            {
              key: 327,
              day: 7,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1050,
            },
            {
              key: 328,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 1550,
            },
            {
              key: 329,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2800,
            },
            {
              key: 330,
              day: 14,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2100,
            },
            {
              key: 331,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2110,
            },
            {
              key: 332,
              day: 30,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3820,
            },
            {
              key: 333,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "15GB",
              price: 3210,
            },
          ],
        },
        {
          title: this.$t("country.USAcanada"),
          array: [
            {
              key: 334,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 2000,
            },
            {
              key: 335,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 3400,
            },
            {
              key: 336,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 5500,
            },
            {
              key: 337,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 500MB",
              price: 3700,
            },
            {
              key: 338,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 7800,
            },
            {
              key: 339,
              day: 14,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1.5GB",
              price: 10700,
            },
            {
              key: 340,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "3GB",
              price: 2200,
            },
            {
              key: 341,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 3400,
            },
            {
              key: 342,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 5500,
            },
          ],
        },
        {
          title: this.$t("country.vietnam"),
          array: [
            {
              key: 343,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 720,
            },
            {
              key: 344,
              day: 3,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1220,
            },
            {
              key: 345,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1120,
            },
            {
              key: 346,
              day: 5,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 1940,
            },
            {
              key: 347,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 1520,
            },
            {
              key: 348,
              day: 7,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 2670,
            },
            {
              key: 349,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2040,
            },
            {
              key: 350,
              day: 10,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 3630,
            },
            {
              key: 351,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 1GB",
              price: 2800,
            },
            {
              key: 352,
              day: 15,
              type: this.$t('newplan.dailyplan'),
              options: "Daily 2GB",
              price: 4980,
            },
            {
              key: 353,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "5GB",
              price: 1260,
            },
            {
              key: 354,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "10GB",
              price: 2140,
            },
            {
              key: 355,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "20GB",
              price: 4020,
            },
            {
              key: 356,
              day: 30,
              type: this.$t('newplan.volumeplan'),
              options: "30GB",
              price: 5800,
            },
          ],
        },
      ];
      return baseData;
    },
  },
  watch: {
    activeKey(key) {
      console.log(key,this.activeKey);
    },
  },
};
