//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getGroupDataplanList } from '@/api/enquiry'
export default {
  data() {
    return {
      value:null,
      planList:[]
    }
  },
  methods:{
    
    onSearch(value){
      if(!value) {
        this.planList = []
        return
      }
      if (value&&value.length < 14) {
        this.$message.warning('The length of the card number is invalid')
        return
      }
      getGroupDataplanList({group:this.$route.params.group, sim: value }).then(res => {
        this.planList = []
        if (res.code==1) {
          res.result = res.result.map(item => ({
            isShow:Object.values(item.pin_puk).some(value => value && value.trim() !== ''),
            ...item,
            pin_puk: Object.entries(item.pin_puk).map(([key, value]) => ({ key, value }))
          }));
          this.planList = res.result
        }else{
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
