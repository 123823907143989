import request from '@/utils/request'

const api = {
    url: '/shopify/getDeviceInfo',
    list:'/shopify/getDataplanList',
    topsi:'/shopify/getTopsiDataplanList',
    group:'/shopify/getGroupDataplanList',
    order:'/shopify/getGygPlanInfo',
    qrcode:'/shopify/getDataplanInfo'
}


export function getDeviceInfo(parameter) {
    return request({
        url: api.url,
        method: 'get',
        params: parameter
    })
}

export function getDataplanList(parameter) {
    return request({
        url: api.list,
        method: 'get',
        params: parameter
    })
}

export function getTopsiDataplanList(parameter) {
    return request({
        url: api.topsi,
        method: 'get',
        params: parameter
    })
}
export function getGroupDataplanList(parameter) {
    return request({
        url: api.group,
        method: 'get',
        params: parameter
    })
}
export function getGygPlanInfo(parameter) {
    return request({
        url: api.order,
        method: 'get',
        params: parameter
    })
}
export function getDataplanInfo(parameter) {
    return request({
        url: api.qrcode,
        method: 'get',
        params: parameter
    })
}