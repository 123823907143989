//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      list:{
        en:{
          iPhone:[
            {text:'1. Settings > Mobile Data',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png'},
            {text:'2. Data Plans and go to the card details',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png'},
            {text:'3. Network Selection',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png'},
            {text:'4. Turn-off automatic and  select the operator #',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png'},
          ],
          Android:[
            {text:'1. Settings > Network and Internet',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png'},
            {text:'2. Select corresponding sim card',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png'},
            {text:'3. Turn-off "Automatically select network" and select the operator#',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png'},
          ]
        },
        cn:{
          iPhone:[
            {text:'1. 点选 “蜂窝网络”',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png'},
            {text:'2. 点选蜂窝号码中对应的卡',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png'},
            {text:'3. 点选 “网络选择”',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png'},
            {text:'4. 关闭自动，进行手动选网#',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png'},
          ],
          Android:[
            {text:'1. 点选 “蜂窝网络”',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png'},
            {text:'2. 点选蜂窝号码中对应的卡',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png'},
            {text:'3. 关闭自动，进行手动选网#',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png'},
          ]
        },
        kr:{
          iPhone:[
            {text:'1. 셀룰러 클릭합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png'},
            {text:'2. 셀룰러에서 해당하는 심카드 선택합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png'},
            {text:'3. 네트워크 선택 클릭합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png'},
            {text:'4. 자동 옵션 닫히고 수동으로 네트워크 선택합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png'},
          ],
          Android:[
            {text:'1. 설정에서 네트워크 선택합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png'},
            {text:'2. 해당하는 심카드 선택합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png'},
            {text:'3. 자동 옵션 닫히고 수동으로 네트워크 선택합니다',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png'},
          ]
        },
        jp:{
          iPhone:[
            {text:'1.「設定」→ 「モバイル通信」',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png'},
            {text:'2. 対応する 「SIMカードを選択」',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png'},
            {text:'3. 「ネットワーク選択」',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png'},
            {text:'4. ネットワークを自動的に選 択する」を「オフ」にする 購入時に指定した通信事業 者を選択',image:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png'},
          ],
          Android:[
            {text:'1. 「設定」→「ネットワーク 及びインターネット」',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png'},
            {text:'2. 対応する「SIMカードを選 択」',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png'},
            {text:'3. ネットワークを自動的に選 択する」を「オフ」にする 購入時に指定した通信事業 者を選択',image:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png'},
          ]
        }
      }
    }
  },
}
