//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      contactShow: false,
      isAbroad:false,
      visible:{
        tips1:false,
        tips2:false,
        tips3:false,
      },
    }
  },
  created(){
    this.$nextTick(()=>{
      this.visible.tips1 = true
      setTimeout(() => {
        this.visible.tips2 = true
      }, 300);
      setTimeout(() => {
        this.visible.tips3 = true
      }, 500);
    });
  },
}
