//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDeviceInfo } from '@/api/enquiry'
import { checkDeviceId } from '@/api/details'
export default {
  data() {
    return {
      viewShow:'initial',
      value:null,
      loading:false,
      DeviceList:[],
    }
  },
  computed:{
    columns(){
      return [
        { title: 'ICCID', dataIndex: 'device_id', scopedSlots: { customRender: 'iccid' }},
        { title: this.$t('enquiry.dataPlan'), dataIndex: 'channel_dataplan_name', scopedSlots: { customRender: 'name' } },
        { title: this.$t('enquiry.status'), dataIndex: 'status_text', scopedSlots: { customRender: 'status' } },
        { title: this.$t('enquiry.usagen'), dataIndex: 'data_usage', scopedSlots: { customRender: 'usage' } },
        { title: this.$t('enquiry.actived'), dataIndex: 'active_time', scopedSlots: { customRender: 'actived' } },
        { title: this.$t('enquiry.expired'), dataIndex: 'expire_time', scopedSlots: { customRender: 'expired' } },
        { title: this.$t('enquiry.end'), dataIndex: 'terminate_time', scopedSlots: { customRender: 'end' } },
        { title: this.$t('enquiry.booking'), dataIndex: 'create_time', scopedSlots: { customRender: 'booking' } },
      ]
    },
    show(){
      if (this.$store.state.login&&this.$store.state.UserDevices.length>0) {
        this.getUserDevices()
        this.viewShow = 'next'
        return true
      }else{
        this.DeviceList = []
        this.viewShow = 'initial'
        return true
      }
    }
  },
  methods:{
    next(value, event){
      if(!value) return
      if (value&&value.length < 14) {
        this.$message.warning(this.$t('details.verify_2'))
        return
      }
      checkDeviceId({ sim: value }).then(res => {
        if(res.code === 1){
          this.viewShow = 'next'
          this.search(value, event)
        }else{
          this.$message.warning(this.$t('details.verify_3'))
        }
      })
    },
    getUserDevices(){
      if (this.$store.state.login&&this.$store.state.UserDevices.length>0) {
        this.DeviceList = []
        this.value = null
        getDeviceInfo({ sim: this.$store.state.UserDevices.toString() }).then(res => {
          if(res.code === 1){
            this.DeviceList = res.result
          }else{
            this.DeviceList = []
          }
        })
      }else{
        this.DeviceList = []
      }
    },
    search(value, event){
      if(event.target.nodeName ==='INPUT' && this.viewShow === 'next'){
        this.getUserDevices()
        return
      }
      if(event.target.nodeName ==='BUTTON') {
        if(!value) {
          this.$message.warning(this.$t('enquiry.verify'))
          return
        }
        if(value&&value.length < 14) {
          this.$message.warning(this.$t('details.verify_2'))
          return
        }
        this.loading = true
        getDeviceInfo({ sim: value }).then(res => {
          this.loading = false
          if(res.code === 1){
            this.DeviceList = res.result
          }else{
            this.DeviceList = []
          }
        })
      }
    }
  }
}
