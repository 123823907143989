//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ImagePreview } from 'vant';
export default {
	i18n:{
		messages:{
			cn:{
				activation:{
					iPhone:{
						step_1:'1.将sim卡插入手机，并进入设置画面',
						step_2:'2. 点击【蜂窝网络】',
						step_3:'3. 请检查以下三个设置：<br> 1) 【蜂窝数据】已打开',
						step_4:'请检查以下三个设置：<br> 2)【蜂窝数据选项】漫游 已打开',
						step_5:'请检查以下三个设置：<br> 3)【网络选择】已自动连接',
						step_6:'4. 在【网络选择】中开启自动',
						step_7:'5. 请耐心等待3-5分钟连接网络',
					},
					Android:{
						step_1:'1. 将sim卡插入手机，并进入设置画面',
						step_2:'2. 点击【sim卡管理器】',
						step_3:'3. 请检查以下三个设置：<br> 1) 【蜂窝数据】已打开',
						step_4:'请检查以下三个设置：<br> 2)【蜂窝数据选项】漫游已打开',
						step_5:'3) 请保持数据漫游开启',
						step_6:'4) 请点选对应的SIM卡',
						step_7:'5) 请点选网络选择',
						step_8:'6) 请检查以下三个设置：<br> 3) 【网络选择】已自动连接',
						step_9:'4. 请耐心等待3-5分钟连接网络',
						step_10:'5. 将显示相关网路营运商的名称',
						step_11:'6. 设置完成静候联网',
					}
				},
				operator:{
					tips:'#请参考商品叙述中的运营商信息',
					iPhone:{
						step_1:'1. 点选 “蜂窝网络”',
						step_2:'2. 点选蜂窝号码中对应的卡',
						step_3:'3. 点选 “网络选择”',
						step_4:'4. 关闭自动，进行手动选网#',
					},
					Android:{
						step_1:'1. 点选 “蜂窝网络”',
						step_2:'2. 点选蜂窝号码中对应的卡',
						step_3:'3. 关闭自动，进行手动选网#',
					}
				},
				CheckAPN:{
					tips:'#请参考商品叙述中的APN信息',
					iPhone:{
						step_1:'1. 设置 > 蜂窝网路 > 蜂窝数据网路',
						step_2:'2. 输入APN名称#  例如 Internet',
					},
					Android:{
						step_1:'1. 打开设置',
						step_2:'2. 点击 SIM卡管理器',
						step_3:'3. 点击进入SIM卡',
						step_4:'4. 点击接入点名称 (APN)',
						step_5:'5. 点击新建APN',
						step_6:'6. 输入APN名称# 例如 Internet',
						step_7:'7. 点击保存',
					}
				},
				RestartSIM:{
					iPhone:{ 
						step_1:'1. 设置 > 蜂窝网络 > 选择对应SIM卡',
						step_2:'2. 点选"SIM卡应用程序"',
						step_3:'3. 点选"国漫服务"',
						step_4:'4. 点选"Reboot SIM"',
					},
					Android:{ 
						step_1:'1. 点击SIM工具包(在某些安卓型号会放在手机设置内）',
						step_2:'2. 选择这个按钮',
						step_3:'3. 选择重启SIM卡',
					}
				}
			},
			en:{
				activation:{
					iPhone:{
						step_1:'Insert SIM Card into mobile phone and go to settings',
						step_2:'Click Mobile Service',
						step_3:'Check three things: <br> 1) Mobile Service is on',
						step_4:'Check three things: <br> 2) Mobile Service Options is roaming on',
						step_5:'Check three things: <br> 3) Network Selection is automatic',
						step_6:'Turn on Automatic in Network Selection',
						step_7:'Wait for 3-5 mins to connect network',
					},
					Android:{
						step_1:'Insert SIM Card into mobile phone and go to settings',
						step_2:'Click SIM card & mobile networks',
						step_3:'Check three things: <br> 1) Mobile Data is on',
						step_4:'Check three things: <br> 2) Mobile Data Options is roaming on',
						step_5:'Set Always in International roaming',
						step_6:'Under SIM card settings',
						step_7:'Select Mobile networks',
						step_8:'Check three things: <br> 3) Network Selection is automatic',
						step_9:'Wait for 3-5 mins to connect network',
						step_10:'The name of the related network operator will show up',
						step_11:'The signal icon indicates the current network',
					}
				},
				operator:{
					tips:'#Please refer to the data plan description',
					iPhone:{
						step_1:'Settings > Mobile Service',
						step_2:'Data Plans and go to the card details',
						step_3:'Network Selection',
						step_4:'Turn-off automatic and select the operator #',
					},
					Android:{
						step_1:'settings > Network and Internet',
						step_2:'Select corresponding sim card',
						step_3:'Turn-off "Automatically select network" and select the operator#',
					}
				},
				CheckAPN:{
					tips:'#Please refer to the data plan description for APN',
					iPhone:{
						step_1:'Go to settings > Mobile Service and select Mobile Service Network',
						step_2:'Insert APN name # e.g: Internet in Mobile Service',
					},
					Android:{
						step_1:'Go to settings',
						step_2:'Click SIM card & mobile networks',
						step_3:'Click in to SIM card',
						step_4:'Click in to Access Point Name',
						step_5:'Click in to New APN',
						step_6:'Insert APN name#: e.g: internet in Name & APN',
						step_7:'Click more and save',
					}
				},
				RestartSIM:{
					iPhone:{ 
						step_1:'1. Select Settings > Cellular Network > Corresponding SIM Card',
						step_2:'2. Press "Sim card application"',
						step_3:'3. Press "国漫服务"',
						step_4:'4. Press "Reboot SIM”',
					},
					Android:{ 
						step_1:'Click SIM toolkit (In settings for some models)',
						step_2:'Select this button',
						step_3:'Then select Reboot SIM',
					}
				}
			},
			jp:{
				activation:{
					iPhone:{
						step_1:'SIMカードを携帯電話に挿入し設定します',
						step_2:'「モバイル通信」を確認ください',
						step_3:'① 「モバイルデータ通信」をON',
						step_4:'②「通信のオプション」の「データローミング」をON',
						step_5:'③「ネットワーク選択」をクリック',
						step_6:'ネットワーク選択で自動をオンにする',
						step_7:'通信開始まで3～5分ほどお待ちください。',
					},
					Android:{
						step_1:'SIMカードを携帯電話に挿入し設定します',
						step_2:'「SIMmobile networks」をクリック',
						step_3:'① 「モバイルデータ通信」をON',
						step_4:'②「データローミング」をON',
						step_5:'データローミングはオンのままにしてください',
						step_6:'対応するSIMカードをクリックしてください',
						step_7:'ネットワーク選択をクリックしてください',
						step_8:'③「モバイルネットワーク」内で自動へ',
						step_9:'通信開始まで3～5分ほどお待ちください',
						step_10:'関連事業者の名前が表示されます',
						step_11:'セットアップが完了し、ネットワーク接続を待機しています',
					}
				},
				operator:{
					iPhone:{
						tips:'#以上説明をご覧ください。Iosのアップデートにより内容が異なる場合がございます。',
						step_1:'「設定」→「モバイル通信」',
						step_2:'対応する「SIMカードを選択」',
						step_3:'「ネットワーク選択」',
						step_4:'ネットワークを自動的に選択する」を「オフ」にする購入時に指定した通信事業者を選択',
					},
					Android:{
						tips:'#以上説明をご覧ください。androidのアップデートにより内容が異なる場合がございます。',
						step_1:'「設定」→「ネットワーク及びインターネット」',
						step_2:'対応する「SIMカードを選択」',
						step_3:'ネットワークを自動的に選択する」を「オフ」にする購入時に指定した通信事業者を選択',
					}
				},
				CheckAPN:{
					tips:'APNについてはデータプランの説明をご覧ください。',
					iPhone:{
						step_1:'「設定」→「モバイル通信」→「モバイルデータ」',
						step_2:'APN名: internet',
					},
					Android:{
						step_1:'「設定」をクリック',
						step_2:'「SIMカード&モバイルネットワーク」をクリック',
						step_3:'本SIMカードを選択',
						step_4:'「アクセスポイント名」をクリック',
						step_5:'「新APN」をクリック',
						step_6:'APN名: internet&APN',
						step_7:'保存　を選択',
					}
				},
				RestartSIM:{
					iPhone:{ 
						step_1:'1. 設定>モバイル通信>SIMカードを選択',
						step_2:'2. クリック"SIM App"',
						step_3:'3. クリック"国漫服务"',
						step_4:'4. クリック"Reboot SIM"',
					},
					Android:{ 
						step_1:'設定したSIMモデルをクリック',
						step_2:'画面の出ているボタンをクリック',
						step_3:'SIMカードを再起動下さい ',
					}
				}
			},
			kr:{
				activation:{
					iPhone:{
						step_1:'01  모바일 휴드폰 유심 트레이는 유심카드를 삽입하시고 설정 화면으로 이동합니다',
						step_2:'셀룰러를 클릭하세요',
						step_3:'아래 3가지 설정 좀 체크해 주십시오：<br> 1）[셀룰러데이터]화성화시킵니다',
						step_4:'아래 3가지 설정 좀 체크해 주십시오：<br> 2）[셀룰러 데이터 옵션]로밍 켜 주세요',
						step_5:'아래 3가지 설정 좀 체크해 주십시오：<br> 3）[셀룰러데이터]화성화시킵니다',
						step_6:'[네트워크 선택]에서 [자동]을 활성화 시킵니다.',
						step_7:'네트워크 접속3~5분 기다려 주십시오',
					},
					Android:{
						step_1:'모바일 휴드폰 유심 트레이는유심카드를삽입하시고설정 화면으로이동합니다',
						step_2:'네트워크 연결 클릭하세요',
						step_3:'아래 3가지 설정 좀 체크해 주십시오：<br> 1）[셀룰러 데이터] 화성화시킵니다',
						step_4:'아래 3가지 설정 좀 체크해 주십시오：<br> 2）[셀룰러 데이터 옵션]로밍 켜 주세요',
						step_5:'[데이터 로밍]을 클릭하세요.',
						step_6:'해당 SIM 카드를 선택하세요',
						step_7:'[모바일 네트워크]를 클릭하세요.',
						step_8:'아래 3가지 설정 좀 체크해 주십시오：<br> 3） [셀룰러 데이터]저동으로화성화시킵니다',
						step_9:'네트워크 접속3~5분 기다려 주십시오',
						step_10:'네트워크 통신사 이름를 확인하세요.',
						step_11:'설정 완료 후 네트워크 연결을 기다려주세요',
					}
				},
				operator:{
					tips:'#아래 상품 통신사 정보를 참고하시기 바랍니다',
					iPhone:{
						step_1:'셀룰러 클릭합니다',
						step_2:'셀룰러에서 해당하는 심카드 선택합니다',
						step_3:'네트워크 선택 클릭합니다',
						step_4:' 자동 옵션 닫히고 수동으로 네트워크 선택합니다',
					},
					Android:{
						step_1:'설정에서 네트워크 선택합니다',
						step_2:'해당하는 심카드 선택합니다',
						step_3:' 자동 옵션 닫히고 수동으로 네트워크 선택합니다',
					}
				},
				CheckAPN:{
					tips:'#아래 상품 설명 APN 정보를 참고하시기 바랍니다',
					iPhone:{
						step_1:'수동 설정 > 셀룰러 및 모바일 셀룰러 선택합니다',
						step_2:'APN 이름을 입력합니다예를 들면 Internet',
					},
					Android:{
						step_1:'설정을 클릭하세요',
						step_2:'설정에서 네트워크 더보기 클릭',
						step_3:'유심카드 클릭하세요',
						step_4:'액세스 포인트 이름(APN)클릭하세요',
						step_5:'NEW APN 클릭하세요',
						step_6:'APN 이름을 입력합니다예를 들면 Internet',
						step_7:'더 저장을 클릭하세요',
					}
				},
				RestartSIM:{
					iPhone:{ 
						step_1:'1. 설정 > 셀룰러 네트워크 > 해당 유심 카드를 선택합니다',
						step_2:'2. “유심카드 어플리케이션"을 누르세요.',
						step_3:'3. "国漫服务"를 누르세요',
						step_4:'4. “재부팅 유심카드" 을 누르세요',
					},
					Android:{ 
						step_1:'SIM툴킷을 클릭하세요(일부 모바일 모델의 설정 에서 적용)',
						step_2:'이 버튼을 선택합니다',
						step_3:'재부팅 SIM 선택하세요',
					}
				}
			},
		}
	},
  data() {
    return {
      imgType:'iPhone',
			// activation:{
			// 	iPhone:[
			// 		{ text:this.$t('activation.iphone.step_1'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-1.png' },
			// 		{ text:'2. 点击【蜂窝网络】', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-2.png' },
			// 		{ text:'3. 请检查以下三个设置：<br> 1) 【蜂窝数据】已打开', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-3.png' },
			// 		{ text:'2)【蜂窝数据选项】漫游 已打开', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-4.png' },
			// 		{ text:'3)【网络选择】已自动连接', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-5.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-6.png' },
			// 		{ text:'4. 请耐心等待3-5分钟连接网络', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-7.png' },
			// 	],
			// 	Android:[
			// 		{ text:'1. 将sim卡插入手机，并进入设置画面', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-1.png' },
			// 		{ text:'2. 点击【sim卡管理器】', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-2.png' },
			// 		{ text:'3.请检查以下三个设置：<br> 1) 【蜂窝数据】已打开', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-3.png' },
			// 		{ text:'2)【数据漫游】已打开', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-4.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-5.png' },
			// 		{ text:'3)【网络选择】已自动连接', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-6.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-7.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-8.png' },
			// 		{ text:'4. 请耐心等待3-5分钟连接网络', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-9.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-10.png' },
			// 		{ text:'', img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-11.png' },
			// 	]
			// },
			// operator:{
			// 	iPhone:[
			// 		{ text:'1. 点选 “蜂窝网络”', img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png' },
			// 		{ text:'2. 点选蜂窝号码中对应的卡', img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png' },
			// 		{ text:'3. 点选 “网络选择”', img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png' },
			// 		{ text:'4. 关闭自动，进行手动选网#', img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png' },
			// 	],
			// 	Android:[
			// 		{ text:'1. 点选 “蜂窝网络”', img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png' },
			// 		{ text:'2. 点选蜂窝号码中对应的卡', img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png' },
			// 		{ text:'3. 关闭自动，进行手动选网#', img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png' },
			// 	]
			// },
			// CheckAPN:{
			// 	iPhone:[
			// 		{ text:'1. 设置 > 蜂窝网路 > 蜂窝数据网路', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/iPhone-en-1.png' },
			// 		{ text:'2. 输入APN名称#  例如 Internet', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/iPhone-en-2.png' },
			// 	],
			// 	Android:[
			// 		{ text:'1. 打开设置', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-1.png' },
			// 		{ text:'2. 点击 SIM卡管理器', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-2.png' },
			// 		{ text:'3. 点击进入SIM卡', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-3.png' },
			// 		{ text:'4. 点击接入点名称 (APN)', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-4.png' },
			// 		{ text:'5. 点击新建APN', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-5.png' },
			// 		{ text:'6. 输入APN名称# 例如 Internet', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-6.png' },
			// 		{ text:'7. 点击保存', img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-7.png' },
			// 	]
			// },
			// RestartSIM:{
			// 	iPhone:[
			// 		{ text:'1. 设置 > 蜂窝网络 > 选择对应SIM卡', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-1.png' },
			// 		{ text:'2. 点选"SIM卡应用程序"', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-2.png' },
			// 		{ text:'3. 点选"国漫服务"', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-3.png' },
			// 		{ text:'4. 点选"Reboot SIM"', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-4.png' },
			// 	],
			// 	Android:[
			// 		{ text:'1. 点击SIM工具包(在某些安卓型号会放在手机设置内）', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-1.png' },
			// 		{ text:'2. 选择这个按钮', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-2.png' },
			// 		{ text:'3. 选择重启SIM卡', img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-3.png' },
			// 	]
			// }
    }
  },
	computed:{
		activation(){
			return {
				iPhone:[
					{ text:this.$t('activation.iPhone.step_1'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-1.png' },
					{ text:this.$t('activation.iPhone.step_2'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-2.png' },
					{ text:this.$t('activation.iPhone.step_3'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-3.png' },
					{ text:this.$t('activation.iPhone.step_4'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-4.png' },
					{ text:this.$t('activation.iPhone.step_5'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-5.png' },
					{ text:this.$t('activation.iPhone.step_6'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-6.png' },
					{ text:this.$t('activation.iPhone.step_7'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FiPhone-en-7.png' },
				],
				Android:[
					{ text:this.$t('activation.Android.step_1'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-1.png' },
					{ text:this.$t('activation.Android.step_2'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-2.png' },
					{ text:this.$t('activation.Android.step_3'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-3.png' },
					{ text:this.$t('activation.Android.step_4'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-4.png' },
					{ text:this.$t('activation.Android.step_5'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-5.png' },
					{ text:this.$t('activation.Android.step_6'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-6.png' },
					{ text:this.$t('activation.Android.step_7'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-7.png' },
					{ text:this.$t('activation.Android.step_8'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-8.png' },
					{ text:this.$t('activation.Android.step_9'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-9.png' },
					{ text:this.$t('activation.Android.step_10'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-10.png' },
					{ text:this.$t('activation.Android.step_11'), img:'https://img.tsimtech.com/SettingTutorial%2Factivation%2FAndroid-en-11.png' },
				]
			}
		},
		operator(){
			return{
				tips:this.$t('operator.tips'),
				iPhoneTips:this.$t('operator.iPhone.tips'),
				AndroidTips:this.$t('operator.Android.tips'),
				iPhone:[
					{ text:this.$t('operator.iPhone.step_1'), img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-1.png' },
					{ text:this.$t('operator.iPhone.step_2'), img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-2.png' },
					{ text:this.$t('operator.iPhone.step_3'), img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-3.png' },
					{ text:this.$t('operator.iPhone.step_4'), img:'https://img.tsimtech.com/SettingTutorial/operator/iPhone-en-4.png' },
				],
				Android:[
					{ text:this.$t('operator.Android.step_1'), img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-1.png' },
					{ text:this.$t('operator.Android.step_2'), img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-2.png' },
					{ text:this.$t('operator.Android.step_3'), img:'https://img.tsimtech.com/SettingTutorial/operator/Android-en-3.png' },
				]
			}	
		},
		CheckAPN(){
			return{
				iPhone:[
					{ text:this.$t('CheckAPN.iPhone.step_1'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/iPhone-en-1.png' },
					{ text:this.$t('CheckAPN.iPhone.step_2'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/iPhone-en-2.png' },
				],
				Android:[
					{ text:this.$t('CheckAPN.Android.step_1'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-1.png' },
					{ text:this.$t('CheckAPN.Android.step_2'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-2.png' },
					{ text:this.$t('CheckAPN.Android.step_3'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-3.png' },
					{ text:this.$t('CheckAPN.Android.step_4'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-4.png' },
					{ text:this.$t('CheckAPN.Android.step_5'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-5.png' },
					{ text:this.$t('CheckAPN.Android.step_6'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-6.png' },
					{ text:this.$t('CheckAPN.Android.step_7'), img:'https://img.tsimtech.com/SettingTutorial/CheckAPN/Android-en-7.png' },
				]
			}
		},
		RestartSIM(){
			return{
				iPhone:[
					{ text:this.$t('RestartSIM.iPhone.step_1'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-1.png' },
					{ text:this.$t('RestartSIM.iPhone.step_2'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-2.png' },
					{ text:this.$t('RestartSIM.iPhone.step_3'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-3.png' },
					{ text:this.$t('RestartSIM.iPhone.step_4'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/iPhone-en-4.png' },
				],
				Android:[
					{ text:this.$t('RestartSIM.Android.step_1'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-1.png' },
					{ text:this.$t('RestartSIM.Android.step_2'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-2.png' },
					{ text:this.$t('RestartSIM.Android.step_3'), img:'https://img.tsimtech.com/SettingTutorial/RestartSim/Android-en-3.png' },
				]
			}
		}
	},
  mounted(){
		document.title = this.$route.query.title
		this.$i18n.locale = this.$route.query.lang
		console.log('mounted：'+this.$i18n.locale);
  },
	destroyed() {
		this.$i18n.locale = localStorage.getItem('TSimLang') ? localStorage.getItem('TSimLang') : 'en'
		console.log('destroyed：'+this.$i18n.locale);
	},
	methods: {
		changeInput(value){
			this.imgType = value
			console.log(value);
		},
		preview(index){
			let images = []
			this.activation.iPhone.forEach(item=>{
				images.push(item.img)
			})
			ImagePreview({
				images: images,
				startPosition: index,
				loop:false
			});
		}
	},
}
